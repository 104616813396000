<template>
	<div>
		<channels v-if="channelLoaded" :channel="channel"></channels>
	</div>
</template>

<script>
import httpClient from '../../services/http.service'

export default {
  name: 'ShowChannel',
  components:{
    Channels: () => import('@/components/ad/Channels')
  },
  data(){
    return{
      channel: {id: 1},
      channelLoaded: false,
    }
  },
  created() {
    this.$route.meta.title = 'Загрузка...'
    this.$router.replace({query: {temp: Date.now()}}) //такая еботня - чтобы заставить роутер обновиться и обновить breadcrubms
    httpClient.get(`ad-channels/${this.$route.params.id}`).then(({data})=>{
      this.channel = data
      this.channelLoaded = true
      this.$route.meta.title = data.name
      this.$router.replace({query: {temp: undefined}}) //такая еботня - чтобы заставить роутер обновиться и обновить breadcrubms
    })
  }
}
</script>

<style scoped>

</style>
